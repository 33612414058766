import React, { FC, useMemo } from 'react';
import Switch from 'components/Switch';
import { priceIcon } from 'assets/icons';
import { SubscriptionPriceDto } from 'models/subscriptions';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Reccuring } from 'helpers/subscriptions';

type Props = {
  passportFeeActive: boolean;
  isOnlyAnnualAvailable: boolean;
  isAnnualSelected: boolean;
  selectedPlanPricing?: SubscriptionPriceDto[];
  onPeriodChange: () => void;
};

const Pricing: FC<Props> = ({
  passportFeeActive,
  isAnnualSelected,
  onPeriodChange,
  selectedPlanPricing,
  isOnlyAnnualAvailable,
}): JSX.Element => {
  const currentPrice = useMemo(() => {
    if (selectedPlanPricing?.length) {
      const currentPrice = selectedPlanPricing.find((price) =>
        isAnnualSelected ? price.reccuring === Reccuring.YEAR : price.reccuring === Reccuring.MONTH
      ) as SubscriptionPriceDto;

      return {
        price: currentPrice?.amount / 100,
        currency: getSymbolFromCurrency(currentPrice?.currency),
      };
    } else {
      return { price: 0, currency: '$' };
    }
  }, [isAnnualSelected, selectedPlanPricing]);

  return (
    <div className="payments-page-pricing">
      {!passportFeeActive && !isOnlyAnnualAvailable && (
        <div className="payments-page-pricing__info-label">
          <img src={priceIcon} alt="icon" />
          <span className="payments-page-pricing__info-label-text">
            Confirm membership within 7 days to waive the one-time passport fee (we got you)
          </span>
        </div>
      )}
      <div className="pricing-wrapper">
        <div className="pricing-info flex-center">
          {!isOnlyAnnualAvailable ? (
            <>
              <div
                className={`pricing-info__price-wrapper ${
                  !passportFeeActive ? 'inactive-text' : ''
                }`}
              >
                {/* <span className="pricing-info__price">$180</span>
                <p className="price-label-text">Passport fee</p> */}
              </div>
              {/* <span className={`pricing-info__plus ${!passportFeeActive ? 'inactive-text' : ''}`}>
                +
              </span> */}
            </>
          ) : null}
          <div className="pricing-info__price-wrapper">
            <span className="pricing-info__price">{`${currentPrice.currency}${currentPrice.price}`}</span>
            <p className="price-label-text">
              {isOnlyAnnualAvailable
                ? 'Renews annually'
                : `Per ${isAnnualSelected ? 'year' : 'month'}`}
            </p>
          </div>
        </div>
        {!isOnlyAnnualAvailable ? (
          <div className="pricing-toggle flex-center">
            <span className="price-label-text pricing-toggle__text">Monthly</span>
            <Switch checked={isAnnualSelected} onChange={onPeriodChange} />
            <span className="price-label-text pricing-toggle__text">Annual</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Pricing;
