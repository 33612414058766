import React, { useState } from 'react';
import { HowYouKnowAboutUs } from 'models/member';

export type RadioItemType = {
  label: string;
  value: string;
  description?: string;
};

type Props = {
  className?: string;
  customValue: string;
  options: Array<RadioItemType>;
  onSelectItem: (value: string) => void;
  onCustomValueChange: (value: string, key: string) => void;
};

const RadioItemsList: React.FC<Props> = ({
  className,
  options,
  onSelectItem,
  customValue,
  onCustomValueChange,
}) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null);

  const handleSelectItem = (index: number) => {
    setSelectedItemIndex(index);
    const currentOptionValue = options[index].value;
    onSelectItem(currentOptionValue);
  };

  const handleChangeCustomValue = (value: string) => {
    onCustomValueChange(value, 'otherText');
  };

  return (
    <div className={`radio-list ${className}`}>
      {options.length
        ? options.map((item, index) => (
            <div
              key={item.value}
              role="button"
              className="radio-list__item"
              onClick={() => handleSelectItem(index)}
            >
              <div className="radio-list__item-button">
                <div className={`button-selector ${selectedItemIndex === index ? 'active' : ''}`} />
              </div>
              {item.value === HowYouKnowAboutUs.OTHER && selectedItemIndex === index ? (
                <input
                  type="text"
                  value={customValue}
                  onChange={(e) => handleChangeCustomValue(e.target.value)}
                  className="radio-list__item_label radio-list__custom-input"
                  autoFocus
                />
              ) : (
                <div className="radio-list__item_label">{item.label}</div>
              )}
            </div>
          ))
        : null}
    </div>
  );
};

export default RadioItemsList;
