import { useAppDispatch, useAppSelector } from 'app/hooks';
import { MemberProfile, Status, HowYouKnowAboutUsType, HowYouKnowAboutUs } from 'models/member';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Steps, useSteps } from 'react-step-builder';
import { profileActions } from 'redux/reducers/profile';
import { selectProfile } from 'redux/selectors/profile';
import { MEMBER_ACCESS_TOKEN } from 'utils/storageKeys';
import CitiesStep, { CitiesValuesModel } from './stepper/CitiesStep';
import StepHeader from './stepper/components/StepHeader';
import ConfirmUser from './stepper/ConfirmUser';
import IndustryStep, { IndustryValuesModel } from './stepper/SocialStep';
import PersonalInfoStep, { PersonalDetailsValuesModel } from './stepper/PersonalInfoStep';
import TravelStoryStep, { TravelStoryValuesModel } from './stepper/TravelStoryStep';
import { getProfilesMissingStep } from 'helpers/onboardingStepperHelper';

interface Props {
  total: number;
  current: number;
  children: any;
  prev?: () => void;
}

const Step: React.FC<Props> = ({ current, total, prev, children }) => {
  return (
    <>
      <StepHeader current={current} total={total} prev={prev} />
      <div className="step-page">{children}</div>
    </>
  );
};

const MobileOnboarding = (): JSX.Element => {
  const { prev, next, total, current, jump, hasNext, hasPrev } = useSteps();
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();
  const user = useAppSelector(selectProfile);
  const referralCode = user?.onboardingSettings?.referredBy?.referralCode || '';

  useEffect(() => {
    dispatch(profileActions.getProfile());
  }, [dispatch]);

  useEffect(() => {
    if (user?.status !== Status.NEW) {
      navigate('/');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (location?.state !== current) {
      jump(location?.state);
    }
  }, [location, current, jump]);

  useEffect(() => {
    if (user && user.profile) {
      setPersonal({
        firstName: user.profile?.firstName,
        lastName: user.profile?.lastName,
        birthday: user.profile?.birthday,
      });
      setCities({
        countryOfResidence: user.profile?.countryOfResidence,
        secondaryCities: user.profile?.secondaryCities?.map((x) => ({
          key: x,
          value: x,
        })),
      });
      setIndustry({
        linkedin: user.profile?.linkedin,
        instagram: user.profile?.instagram,
      });
      setTravelStory({
        travelStory: user.profile?.travelStory,
      });
    }
  }, [user]);

  const [personal, setPersonal] = useState<PersonalDetailsValuesModel>({});
  const [cities, setCities] = useState<CitiesValuesModel>({});
  const [industry, setIndustry] = useState<IndustryValuesModel>({});
  const [travelStory, setTravelStory] = useState<TravelStoryValuesModel>({});
  const [confirmUser, setConfirmUser] = useState<HowYouKnowAboutUsType>({});

  const finishOnboarding = (data: HowYouKnowAboutUsType) => {
    data.userId = user?._id;
    dispatch(profileActions.confrimMember(data));
  };

  const updateProfile = (model: Partial<MemberProfile>) => {
    dispatch(profileActions.updateProfile(model));
  };

  const handlePrev = () => {
    if (hasPrev) navigate('/onboarding', { state: current - 1 });
    prev();
  };

  const handleNext = () => {
    if (referralCode !== '' && current === 4) {
      // if user has referral code, then skip "ConfirmUser" step
      // and send data with preselected referral option
      const data: HowYouKnowAboutUsType = {
        howYouKnowAboutUs: HowYouKnowAboutUs.REFERRAL,
        referralCode,
      };
      finishOnboarding(data);
      next();
    } else {
      if (hasNext) navigate('/onboarding', { state: current + 1 });
      next();
    }
  };

  return (
    <Steps startsFrom={getProfilesMissingStep(user)}>
      <Step
        key={1}
        current={current}
        total={total}
        prev={() => {
          localStorage.removeItem(MEMBER_ACCESS_TOKEN);
          navigate('/login');
        }}
      >
        <PersonalInfoStep
          next={handleNext}
          model={personal}
          onSubmit={(model) => {
            setPersonal(model);
            updateProfile(model);
          }}
        />
      </Step>
      <Step key={2} current={current} total={total} prev={handlePrev}>
        <CitiesStep
          next={handleNext}
          model={cities}
          onSubmit={(model) => {
            setCities(model);
            updateProfile({
              countryOfResidence: model.countryOfResidence,
              secondaryCities: model.secondaryCities?.map((x) => x.value),
            } as Partial<MemberProfile>);
          }}
        />
      </Step>
      <Step key={3} current={current} total={total} prev={handlePrev}>
        <IndustryStep
          next={handleNext}
          model={industry}
          onSubmit={(model) => {
            setIndustry(model);
            updateProfile(model);
          }}
        />
      </Step>
      <Step key={4} current={current} total={total} prev={handlePrev}>
        <TravelStoryStep
          next={handleNext}
          model={travelStory}
          onSubmit={(model) => {
            setTravelStory(model);
            updateProfile(model);
          }}
        />
      </Step>
      <Step key={5} current={current} total={total} prev={handlePrev}>
        <ConfirmUser
          next={handleNext}
          model={confirmUser}
          onSubmit={(model) => {
            setConfirmUser(model);
            finishOnboarding(model);
          }}
        />
      </Step>
    </Steps>
  );
};

export default MobileOnboarding;
