import { PayloadAction } from '@reduxjs/toolkit';
import hotspotsApi from 'api/hotspots';
import axios from 'axios';
import {
  HotspotDto,
  HotspotViewResponse,
  HotspotViewRequest,
  HotspotViewVerify,
} from 'models/hotspot';
import { call, put, takeLatest } from 'redux-saga/effects';
import { hotspotActions } from 'redux/reducers/hotspot';
import history from 'utils/route-history';

function* getHotspot(data: PayloadAction<string>) {
  try {
    const response: HotspotDto = yield call(hotspotsApi.getHotspot, data.payload);
    yield put(hotspotActions.getHotspotSuccess(response));
  } catch (error) {
    console.log(`Failed to get hotspot`, error);
    if (axios.isAxiosError(error)) {
      yield put(hotspotActions.getHotspotFailed(error.message));
    }
  }
}

function* verifyAndGetGuideView(data: PayloadAction<HotspotViewVerify>) {
  try {
    const response: HotspotViewResponse = yield call(
      hotspotsApi.verifyAndGetGuideView,
      data.payload
    );
    yield put(hotspotActions.verifyAndGetGuideViewSuccess(response));
  } catch (error) {
    console.log(`Failed to get hotspot view`, error);
    history.push(`/guide-view-request/${data.payload.id}`);
    if (axios.isAxiosError(error)) {
      yield put(hotspotActions.verifyAndGetGuideViewFailed(error.message));
    }
  }
}

function* requestGuideView(data: PayloadAction<HotspotViewRequest>) {
  try {
    const response: HotspotViewResponse = yield call(hotspotsApi.requestGuideView, data.payload);
    yield put(hotspotActions.requestGuideViewSuccess(response));
    if (response) {
      history.push(`/guide-view/${response._id}`);
    }
  } catch (error) {
    console.log(`Failed to request hotspot view`, error);
    if (axios.isAxiosError(error)) {
      yield put(hotspotActions.requestGuideViewFailed(error.message));
    }
  }
}

export default function* hotspotSaga() {
  yield takeLatest(hotspotActions.getHotspot.type, getHotspot);
  yield takeLatest(hotspotActions.verifyAndGetGuideView.type, verifyAndGetGuideView);
  yield takeLatest(hotspotActions.requestGuideView.type, requestGuideView);
}
