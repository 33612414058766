import { PayloadAction } from '@reduxjs/toolkit';
import profileApi from 'api/profile';
import axios from 'axios';
import { MemberDto, MemberProfile, HowYouKnowAboutUsType, reApplyMemberType } from 'models/member';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { profileActions } from 'redux/reducers/profile';
import { selectProfile } from 'redux/selectors/profile';
import history from 'utils/route-history';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

function* getProfile() {
  try {
    const response: MemberDto = yield call(profileApi.getProfile);
    yield put(profileActions.getProfileSuccess(response));
  } catch (error) {
    console.log(`Failed to get member profile`, error);
    if (axios.isAxiosError(error)) {
      yield put(profileActions.getProfileFailed(error.message));
    }
  }
}

function* reApplyMember(data: PayloadAction<reApplyMemberType>) {
  try {
    yield call(profileApi.reApplyMember, data.payload);
    yield put(profileActions.reApplyMemberSuccess(data.payload));
    history.push('/waiting-review');
  } catch (error) {
    console.log(`Failed to send re-apply message`, error);
    if (axios.isAxiosError(error)) {
      yield put(profileActions.reApplyMemberFailed(error.message));
    }
  }
}

function* updateProfile(data: PayloadAction<MemberProfile>) {
  try {
    yield call(profileApi.updateProfile, data.payload);
    yield put(profileActions.updateProfileSuccess(data.payload));
  } catch (error) {
    console.log(`Failed to update member profile`, error);
    if (axios.isAxiosError(error)) {
      yield put(profileActions.updateProuleFailed(error.message));
    }
  }
}

function* confrimMember(data: PayloadAction<HowYouKnowAboutUsType>) {
  try {
    yield call(profileApi.confrimMember, data.payload);
    yield getProfile(); // get profile function result (success or fail) should set the loading flag to false in the profile reducer
    ReactGA.event('application_complete', { userId: data.payload.userId });
    ReactPixel.trackCustom('application_complete', { userId: data.payload.userId });

    const user: ReturnType<typeof selectProfile> = yield select(selectProfile);

    if (!user?.onboardingSettings?.expediteCode) {
      history.push('/waiting-review');
    } else {
      history.push('/membership-selection');
    }
  } catch (error) {
    console.log(`Failed to confirm member`, error);
    if (axios.isAxiosError(error)) {
      yield put(profileActions.confrimMemberFailed(error.response?.data.message));
    }
  }
}

function* getMyReferrals() {
  try {
    const response: MemberDto[] = yield call(profileApi.getReferrals);
    yield put(profileActions.getReferralsSuccess(response));
  } catch (error) {
    console.log(`Failed to get member referrals`, error);
    if (axios.isAxiosError(error)) {
      yield put(profileActions.getReferralsFailed(error.message));
    }
  }
}

export default function* profileSaga() {
  yield takeLatest(profileActions.getProfile.type, getProfile);
  yield takeLatest(profileActions.updateProfile.type, updateProfile);
  yield takeLatest(profileActions.confrimMember.type, confrimMember);
  yield takeLatest(profileActions.getReferrals.type, getMyReferrals);
  yield takeLatest(profileActions.reApplyMember.type, reApplyMember);
}
