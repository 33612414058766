import { Navigate, useLocation } from 'react-router-dom';
import { MEMBER_ACCESS_TOKEN } from 'utils/storageKeys';

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const token = localStorage.getItem(MEMBER_ACCESS_TOKEN);
  let location = useLocation();

  if (!token) {
    localStorage.setItem('sourceURL', location.pathname);
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;
