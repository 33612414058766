import { Button, FrontBanner, Input } from 'components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { useAppSelector } from 'app/hooks';
import { selectProfile } from 'redux/selectors/profile';
import { useEffect } from 'react';

interface Props {
  model: PersonalDetailsValuesModel;
  onSubmit: (model: PersonalDetailsValuesModel) => void;
  next: () => void;
}

yup.addMethod(yup.string, 'isValidDate', function (args) {
  const { message, maxDate } = args;
  return this.test('isValid', message, function (value, originalValue) {
    const { path, createError } = this;

    if (!moment(value).isValid()) {
      return createError({ message: 'Date is not valid', path });
    }

    return moment(value).isBefore(moment(maxDate)) || createError({ message, path });
  });
});

const validationSchema = yup.object().shape({
  firstName: yup.string().trim().required('Required'),
  lastName: yup.string().trim().required('Required'),
  birthday: yup
    .string()
    .required('Required')
    // @ts-ignore
    .isValidDate({ maxDate: new Date('2015-01-01'), message: 'Date should be less' }),
  email: yup.string().email('Enter a valid email').required('Required'),
});

export interface PersonalDetailsValuesModel {
  firstName?: string;
  lastName?: string;
  birthday?: string;
  email?: string;
}

const PersonalInfoStep: React.FC<Props> = ({ onSubmit, model, next }) => {
  const profile = useAppSelector(selectProfile);

  const submitHandler = (values: PersonalDetailsValuesModel): void => {
    onSubmit(values);
    next();
  };

  const formik = useFormik({
    initialValues: {
      firstName: model.firstName,
      lastName: model.lastName,
      birthday: model.birthday,
      email: model.email,
    },
    validationSchema: validationSchema,
    onSubmit: submitHandler,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (profile && !formik.values.email) {
      formik.setValues({ ...formik.values, email: profile.email });
    }
  }, [profile]);

  const handleNameChange = (value: string, formikField: 'lastName' | 'firstName') => {
    const current = formik.values[formikField];
    const text = value.trim();
    if (!text?.length && !current) return;
    formik.setFieldValue(formikField, value);
  };

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off" className="form-container">
      <FrontBanner mainText="The Basics" />
      <Input
        id={'email'}
        name={'email'}
        type={'email'}
        label={'Email'}
        value={formik.values.email || ''}
        onChange={formik.handleChange}
        isError={formik.touched.email && Boolean(formik.errors.email)}
        errorText={formik.errors.email}
      />
      <Input
        id={'firstName'}
        name={'firstName'}
        type={'text'}
        label={'First Name'}
        value={formik.values.firstName || ''}
        onChange={formik.handleChange}
        isError={formik.touched.firstName && Boolean(formik.errors.firstName)}
        errorText={formik.errors.firstName}
      />
      <Input
        id={'lastName'}
        name={'lastName'}
        type={'text'}
        label={'Last Name'}
        value={formik.values.lastName || ''}
        onChange={formik.handleChange}
        isError={formik.touched.lastName && Boolean(formik.errors.lastName)}
        errorText={formik.errors.lastName}
      />

      <Input
        id={'birthday'}
        type={'text'}
        name={'birthday'}
        label={'Birthday'}
        placeholder="YYYY-MM-DD"
        mask={'9999-99-99'}
        alwaysShowMask={false}
        value={formik.values.birthday || ''}
        onChange={formik.handleChange}
        isError={formik.touched.birthday && Boolean(formik.errors.birthday)}
        errorText={formik.errors.birthday}
      />
      <Button type={'submit'} text={'Next'} />
    </form>
  );
};

export default PersonalInfoStep;
