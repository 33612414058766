import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LoginMemberRequest,
  LoginMemberResponse,
  SendMemberOtpRequest,
  SetPasswordRequest,
  TryLoginMemberRequest,
  TryLoginMemberResponse
} from 'types';

export interface AuthState {
  loading: boolean;
  accessToken: string | null;
  error: any;
}

const initialState: AuthState = {
  loading: false,
  accessToken: null,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    sendOtpCode(state: AuthState, action: PayloadAction<SendMemberOtpRequest>) {
      state.loading = true;
    },
    sendOtpCodeSuccess(state: AuthState) {
      state.loading = false;
    },
    sendOtpCodeFailed(state: AuthState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    login(state: AuthState, action: PayloadAction<LoginMemberRequest>) {
      state.loading = true;
    },
    loginSuccess(state: AuthState, action: PayloadAction<TryLoginMemberResponse>) {
      state.loading = false;
      state.accessToken = action.payload.jwt.accessToken;
    },
    loginFailed(state: AuthState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    tryLoginMember(state: AuthState, action: PayloadAction<TryLoginMemberRequest>) {
      state.loading = true;
    },
    tryLoginMemberSuccess(state: AuthState, action: PayloadAction<TryLoginMemberResponse>) {
      state.loading = false;
      state.accessToken = action.payload.jwt.accessToken;
    },
    tryLoginMemberFailed(state: AuthState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    setPassword(state: AuthState, action: PayloadAction<SetPasswordRequest>) {
      state.loading = true;
    },
    setPasswordSuccess(state: AuthState) {
      state.loading = false;
    },
    setPasswordFailed(state: AuthState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Actions
export const authActions = authSlice.actions;

// Reducer
const authReducer = authSlice.reducer;
export default authReducer;
