import React, { ChangeEvent, useRef } from 'react';
import InputMask from 'react-input-mask';

interface Props {
  id: string;
  name: string;
  type: 'text' | 'number' | 'email' | 'submit' | 'password';
  maxLength?: number;
  min?: number;
  max?: number;
  value: string;
  label: string;
  isError?: boolean;
  errorText?: string;
  placeholder?: string;
  prefix?: string;
  autoFocus?: boolean;
  alwaysShowMask?: boolean;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  mask?: string; // in case you're using a mask you need to handle the masked value format in your parent component because react-input-mask returns a masked value
  containerStyle?: any;
  labelStyle?: any;
  inputStyle?: any;
}

const Input: React.FC<Props> = ({
  id,
  name,
  value,
  type,
  maxLength,
  min,
  max,
  label,
  isError,
  errorText,
  placeholder,
  onChange,
  mask,
  autoFocus,
  alwaysShowMask = true,
  prefix,
  containerStyle,
  labelStyle,
  inputStyle,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const defaultProps = {
    id: id,
    name: name,
    maxLength: maxLength,
    type: type,
    className: 'input',
    placeholder: placeholder,
    value: value,
    min: min,
    max: max,
    onChange: onChange,
    autoFocus: autoFocus,
  };

  return (
    <>
      <div
        className={`input-container ${isError ? 'error' : ''}`}
        style={containerStyle}
        onClick={() => inputRef?.current?.focus()}
      >
        <span className={`input-label ${isError ? 'error' : ''}`} style={labelStyle}>
          {label}
        </span>
        <div
          style={{
            display: 'flex',
          }}
        >
          {prefix && <span className="beaufortPro-normal prefix">{prefix}</span>}
          {mask ? (
            <InputMask mask={mask} alwaysShowMask={alwaysShowMask} {...defaultProps} />
          ) : (
            <input ref={inputRef} {...defaultProps} style={inputStyle} />
          )}
        </div>
      </div>
      {isError && <span className="input-label-error">{errorText}</span>}
    </>
  );
};

export default Input;
