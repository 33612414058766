import React from 'react';

interface Props {
  mainText: string;
  subTitle?: string;
  description?: string;
  image?: string;
  className?: string;
  children?: JSX.Element;
}

const FrontBanner: React.FC<Props> = ({ mainText, description, subTitle, className }) => {
  const bannerClassName = className ?? '';

  return (
    <div className={`banner-container ${bannerClassName}`}>
      <div className="banner-text-container">
        <span className="banner-main-text">{mainText}</span>
        <span className="banner-main-text">{subTitle}</span>
        {description && <span className="banner-description">{description}</span>}
      </div>
    </div>
  );
};

export default FrontBanner;
