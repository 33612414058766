import React, { FC } from 'react';
import { checkMark } from 'assets/icons';
import { SubscriptionDetailsDto } from 'models/subscriptions';

const SubscriptionDetailInfo: FC<{ selectedSubscriptionInfo?: SubscriptionDetailsDto }> = ({
  selectedSubscriptionInfo,
}): JSX.Element => {
  return (
    <div className="subscription-details">
      <div className="subscription-details__wrapper">
        <div className="subscription-details__heading">
          <div className="subscription-plans-description__item_label">
            {selectedSubscriptionInfo?.name}
          </div>
          <div className="subscription-plans-description__item_description">
            {selectedSubscriptionInfo?.description}
          </div>
        </div>
        <div className="subscription-details__feature-list ">
          {selectedSubscriptionInfo && selectedSubscriptionInfo.features.length
            ? selectedSubscriptionInfo.features.map((feature) => (
                <div className="subscription-details__feature-item flex-center" key={feature}>
                  <img src={checkMark} alt="check_icon" />
                  <div className="subscription-plans-description__item_description">{feature}</div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDetailInfo;
