import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Button, Header, Input } from 'components';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { authActions } from 'redux/reducers/auth';
import { selectAuthError } from 'redux/selectors/auth';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  code: yup
    .string()
    .required('Required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .length(6, 'Must be exactly 6 digits')
    .max(6, 'Must be exactly 6 digits'),
});

export interface ConfimLoginValuesModel {
  code: string;
}

const ConfirmLogin = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const confirmLoginError = useAppSelector(selectAuthError);

  const location = useLocation();
  const navigate = useNavigate();

  const submitHandler = useCallback(
    (values: ConfimLoginValuesModel): void => {
      dispatch(
        authActions.login({
          code: values.code.toString(),
          email: location.state.email,
        })
      );
    },
    [dispatch, location.state.email]
  );

  const resendCode = () => {
    dispatch(
      authActions.sendOtpCode({
        email: location.state.email,
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: validationSchema,
    onSubmit: submitHandler,
    validateOnBlur: true,
  });

  const onChangeHandler = (value: string) => {
    // replace the masked value
    formik.setFieldValue('code', value.replace(/\D/g, ''));

    if (confirmLoginError) {
      dispatch(authActions.loginFailed(null));
    }
  };

  return (
    <div>
      <Header onBack={() => navigate(-1)} withBackArrow></Header>
      <div className="page">
        <div className="container">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="confirm-login-container">
              <span className="confirm-login-main-text">Check your email for an access code</span>
              <Input
                id={'code'}
                name={'code'}
                type={'text'}
                label={'Access Code'}
                value={formik.values.code}
                mask={'9 9 9 - 9 9 9'}
                autoFocus
                onChange={(e) => {
                  onChangeHandler(e.target.value);
                }}
                isError={
                  formik.touched.code && (Boolean(formik.errors.code) || Boolean(confirmLoginError))
                }
                errorText={formik.errors.code || confirmLoginError}
              />
              <div className="confirm-login-repeat-container">
                <span className="confirm-login-repeat-text-second" onClick={resendCode}>
                  Send me a new access code
                </span>
              </div>
              <Button type={'submit'} text="Next" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ConfirmLogin;
