import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MemberDto, MemberProfile, HowYouKnowAboutUsType, reApplyMemberType } from 'models/member';

export interface ProfileState {
  loading: boolean;
  data: MemberDto | null;
  referrals: MemberDto[] | null;
  error: any;
}

const initialState: ProfileState = {
  loading: false,
  data: null,
  referrals: null,
  error: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    getProfile(state: ProfileState, action: PayloadAction) {
      state.loading = true;
    },
    getProfileSuccess(state: ProfileState, action: PayloadAction<MemberDto>) {
      state.loading = false;
      state.data = action.payload;
    },
    getProfileFailed(state: ProfileState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateProfile(
      state: ProfileState,
      action: PayloadAction<Partial<MemberProfile>>
    ) {
      state.loading = true;
    },
    updateProfileSuccess(
      state: ProfileState,
      action: PayloadAction<Partial<MemberProfile>>
    ) {
      state.loading = false;
      const data = state.data;
      if (data) {
        data.profile = { ...data.profile, ...action.payload };
      }
      state.data = data;
    },
    updateProuleFailed(state: ProfileState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    confrimMember(
      state: ProfileState,
      action: PayloadAction<HowYouKnowAboutUsType>
    ) {
      state.loading = true;
    },
    confrimMemberSuccess(
      state: ProfileState,
      action: PayloadAction<HowYouKnowAboutUsType>
    ) {
      state.loading = false;
    },
    confrimMemberFailed(state: ProfileState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getReferrals(state: ProfileState, action: PayloadAction) {
      state.loading = true;
    },
    getReferralsSuccess(
      state: ProfileState,
      action: PayloadAction<MemberDto[]>
    ) {
      state.loading = false;
      state.referrals = action.payload;
    },
    getReferralsFailed(state: ProfileState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    reApplyMember(state: ProfileState, action: PayloadAction<reApplyMemberType>) {
      state.loading = true;
    },
    reApplyMemberSuccess(
      state: ProfileState,
      action: PayloadAction<reApplyMemberType>
    ) {
      state.loading = false;
    },
    reApplyMemberFailed(state: ProfileState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

  },
});

// Actions
export const profileActions = profileSlice.actions;

// Reducer
const profileReducer = profileSlice.reducer;
export default profileReducer;
