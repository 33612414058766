import React from 'react';
import { FrontBanner, Header } from 'components';
import { useNavigate } from 'react-router-dom';
import { MEMBER_ACCESS_TOKEN } from 'utils/storageKeys';

const Paused = (): JSX.Element => {
  const navigate = useNavigate();

  const handleLogOut = () => {
    localStorage.removeItem(MEMBER_ACCESS_TOKEN);
    navigate('/login');
  };

  return (
    <div>
      <Header withBackArrow={false}></Header>
      <div className="page">
        <div className="login-container container">
          <FrontBanner
            mainText="Your membership is paused"
          />
          <div className="banner-text-container">
            <h5 className="banner-description">
              If you have any questions, please get in touch at <br />
              <a
                className="banner-description"
                href="mailto:support@mapout.com"
              >
                hello@yayem.co
              </a>
            </h5>
          </div>
          <div className="banner-text-container">
          <div className="logout-wrap">
            <span onClick={handleLogOut} className="logout dia-bold">
              log out
            </span>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Paused;
