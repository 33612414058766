import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  HotspotDto,
  HotspotViewResponse,
  HotspotViewRequest,
  HotspotViewVerify,
} from '../../models/hotspot';

export interface HotspotState {
  loading: boolean;
  publicData: HotspotDto | null;
  privateData: HotspotViewResponse | null;
  error: any;
}

const initialState: HotspotState = {
  loading: false,
  publicData: null,
  privateData: null,
  error: null,
};

const hotspotSlice = createSlice({
  name: 'hotspot',
  initialState: initialState,
  reducers: {
    getHotspot(state: HotspotState, action: PayloadAction<string>) {
      state.loading = true;
    },
    getHotspotSuccess(state: HotspotState, action: PayloadAction<HotspotDto>) {
      state.loading = false;
      state.publicData = action.payload;
    },
    getHotspotFailed(state: HotspotState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    verifyAndGetGuideView(state: HotspotState, action: PayloadAction<HotspotViewVerify>) {
      state.loading = true;
    },
    verifyAndGetGuideViewSuccess(state: HotspotState, action: PayloadAction<HotspotViewResponse>) {
      state.loading = false;
      state.privateData = action.payload;
    },
    verifyAndGetGuideViewFailed(state: HotspotState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    requestGuideView(state: HotspotState, action: PayloadAction<HotspotViewRequest>) {
      state.loading = true;
    },
    requestGuideViewSuccess(state: HotspotState, action: PayloadAction<HotspotViewResponse>) {
      state.loading = false;
      state.privateData = action.payload;
    },
    requestGuideViewFailed(state: HotspotState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Actions
export const hotspotActions = hotspotSlice.actions;

// Reducer
const hotspotReducer = hotspotSlice.reducer;
export default hotspotReducer;
