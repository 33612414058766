import { PayloadAction } from '@reduxjs/toolkit';
import authApi from 'api/auth';
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { authActions } from 'redux/reducers/auth';
import {
  LoginMemberRequest,
  LoginMemberResponse,
  SendMemberOtpRequest,
  SetPasswordRequest,
  TryLoginMemberRequest,
  TryLoginMemberResponse,
} from 'types';
import history from 'utils/route-history';
import { MEMBER_ACCESS_TOKEN } from 'utils/storageKeys';
import ReactGA from 'react-ga4';
import { MemberDto, Status } from 'models/member';
import profileApi from 'api/profile';
import ReactPixel from 'react-facebook-pixel';

function* sendOtpCode(data: PayloadAction<SendMemberOtpRequest>) {
  try {
    yield call(authApi.sendOtpCode, data.payload);
    yield put(authActions.sendOtpCodeSuccess());
  } catch (error) {
    console.log(`Failed to send otp code`, error);
    if (axios.isAxiosError(error)) {
      yield put(authActions.sendOtpCodeFailed(error.message));
    }
  }
}

function* login(data: PayloadAction<LoginMemberRequest>) {
  try {
    const response: TryLoginMemberResponse = yield call(authApi.login, data.payload);
    yield put(authActions.loginSuccess(response));
    localStorage.setItem(MEMBER_ACCESS_TOKEN, response.jwt.accessToken);
    const member: MemberDto = yield call(profileApi.getProfile);
    if (member.status === Status.NEW) {
      ReactGA.event('application_start', { userId: member._id });
      ReactPixel.trackCustom('application_start', { userId: member._id });
    }
    const sourceURL = localStorage.getItem('sourceURL');
    if (sourceURL) {
      history.push(sourceURL);
      localStorage.removeItem('sourceURL');
    } else {
      history.push('/');
    }
  } catch (error) {
    console.log(`Failed to login`, error);
    if (axios.isAxiosError(error)) {
      yield put(authActions.loginFailed(error.response?.data.message));
    }
  }
}

function* tryLoginMember(data: PayloadAction<TryLoginMemberRequest>) {
  try {
    const response: TryLoginMemberResponse = yield call(authApi.tryLoginMember, data.payload);
    const sourceURL = localStorage.getItem('sourceURL');
    if (response.action === 'none') {
      yield put(authActions.tryLoginMemberSuccess(response));
      localStorage.setItem(MEMBER_ACCESS_TOKEN, response.jwt.accessToken);
      const member: MemberDto = yield call(profileApi.getProfile);
      if (member.status === Status.NEW) {
        ReactGA.event('application_start', { userId: member._id });
        ReactPixel.trackCustom('application_start', { userId: member._id });
      }
      if (sourceURL) {
        history.push(sourceURL);
        localStorage.removeItem('sourceURL');
      } else {
        history.push('/');
      }
    } else {
      history.push('/confirm-login', { email: data.payload.email });
    }
  } catch (error) {
    console.log(`Failed to try login member`, error);
    if (axios.isAxiosError(error)) {
      yield put(authActions.tryLoginMemberFailed(error.response?.data.message));
    }
  }
}

function* setPassword(data: PayloadAction<SetPasswordRequest>) {
  try {
    yield call(authApi.setPassword, data.payload);
    yield put(authActions.setPasswordSuccess());
    history.push('/success');
  } catch (error) {
    console.log(`Failed to login`, error);
    if (axios.isAxiosError(error)) {
      yield put(authActions.setPasswordFailed(error.response?.data.message));
    }
  }
}

export default function* authSaga() {
  yield takeLatest(authActions.sendOtpCode.type, sendOtpCode);
  yield takeLatest(authActions.login.type, login);
  yield takeLatest(authActions.setPassword.type, setPassword);
  yield takeLatest(authActions.tryLoginMember.type, tryLoginMember);
}
