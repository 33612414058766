import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Status } from 'models/member';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { profileActions } from 'redux/reducers/profile';
import { selectProfile } from 'redux/selectors/profile';
import { MEMBER_ACCESS_TOKEN } from 'utils/storageKeys';

const Layout = (): JSX.Element => {
  const token = localStorage.getItem(MEMBER_ACCESS_TOKEN);

  const profile = useAppSelector(selectProfile);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token) {
      dispatch(profileActions.getProfile());
    }
  }, [token, navigate, dispatch]);

  const redirectTo = useCallback(() => {
    if (!profile) {
      navigate('/');
    }

    switch (profile?.status) {
      case Status.NEW: {
        navigate('/onboarding');
        break;
      }
      case Status.AWAITING_REVIEW: {
        navigate('/waiting-review');
        break;
      }
      case Status.WAITLIST: {
        navigate('/waiting-review');
        break;
      }
      case Status.INACTIVE: {
        navigate('/inactive');
        break;
      }
      case Status.PAUSED: {
        navigate('/paused');
        break;
      }
      case Status.APPROVED: {
        navigate('/membership-selection');
        break;
      }
      case Status.ACTIVE:
      case Status.PAST_DUE: {
        if (!profile.isPasswordSet) {
          navigate('/set-password');
        } else {
          navigate('/welcome');
        }
        break;
      }
      default:
        navigate('/');
    }
  }, [profile, navigate]);

  useEffect(() => {
    if (!token) {
      navigate('/login');
    } else redirectTo();
  }, [token, profile, navigate, redirectTo]);

  return <div></div>;
};

export default Layout;
