import {
  HotspotDto,
  HotspotViewRequest,
  HotspotViewResponse,
  HotspotViewVerify,
} from '../models/hotspot';
import publicAxiosApi from './config';

const hotspotsApi = {
  getHotspot(id: string): Promise<HotspotDto> {
    const url = `api/onboarding/hotspots/${id}`;
    return publicAxiosApi.get(url);
  },

  verifyAndGetGuideView(data: HotspotViewVerify): Promise<HotspotViewResponse> {
    const url = `api/onboarding/hotspots/verify-guide-view/${data.id}?code=${data.otp}&userId=${data.userId}`;
    return publicAxiosApi.get(url);
  },

  requestGuideView(data: HotspotViewRequest): Promise<HotspotViewResponse> {
    const url = `api/onboarding/hotspots/request-guide-view/${data._id}`;
    return publicAxiosApi.request({ url, method: 'POST', data });
  },
};

export default hotspotsApi;
