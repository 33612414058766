import { Button, FrontBanner } from 'components';
import { useFormik } from 'formik';
import { HowYouKnowAboutUs, HowYouKnowAboutUsType } from 'models/member';
import { FC, useCallback } from 'react';
import * as yup from 'yup';
import RadioItemsList, { RadioItemType } from 'components/RadioItemsList';
import { useAppSelector } from 'app/hooks';
import { selectProfileLoading } from 'redux/selectors/profile';

const options: RadioItemType[] = [
  { label: 'Latitude 59', value: HowYouKnowAboutUs.LATITUDE_59 },
  { label: 'Instagram', value: HowYouKnowAboutUs.INSTAGRAM },
  { label: 'A Yayem Experience', value: HowYouKnowAboutUs.YAYEM_EXPERIENCE },
  { label: 'Referral', value: HowYouKnowAboutUs.REFERRAL },
  { label: 'Other', value: HowYouKnowAboutUs.OTHER },
];

const validationSchema = yup.object().shape({
  otherText: yup.string().when('howYouKnowAboutUs', {
    is: HowYouKnowAboutUs.OTHER,
    then: yup.string(),
  }),
});

export interface ConfirmUserValuesModel {
  howYouKnowAboutUs?: HowYouKnowAboutUs;
  otherText: string;
}

interface Props {
  model: HowYouKnowAboutUsType;
  onSubmit: (model: HowYouKnowAboutUsType) => void;
  next: () => void;
}

const ConfirmUser: FC<Props> = ({ next, onSubmit, model }) => {
  const loading = useAppSelector(selectProfileLoading);

  const submitHandler = useCallback(
    (values: ConfirmUserValuesModel): void => {
      const data: HowYouKnowAboutUsType = {
        howYouKnowAboutUs: values.howYouKnowAboutUs,
      };

      if (values.otherText) {
        data.otherText = values.otherText;
      }

      if (values.howYouKnowAboutUs === HowYouKnowAboutUs.REFERRAL) {
        data.referralCode = '';
      }

      onSubmit(data);
      next();
    },
    [onSubmit]
  );

  const formik = useFormik({
    initialValues: {
      howYouKnowAboutUs: undefined,
      otherText: '',
    },
    validationSchema: validationSchema,
    onSubmit: submitHandler,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleOptionChange = (value: string) => {
    formik.resetForm({});
    formik.setFieldValue('howYouKnowAboutUs', value);
  };

  const onCustomValueChange = (value: string, key: string) => {
    formik.setFieldValue(key, value);
  };

  return (
    <form onSubmit={formik.handleSubmit} className="form-container">
      <FrontBanner mainText="How’d you find us?" />
      <RadioItemsList
        options={options}
        customValue={formik.values.otherText}
        onSelectItem={handleOptionChange}
        onCustomValueChange={onCustomValueChange}
      />
      <Button disabled={loading} type={'submit'} text={'Submit'} />
    </form>
  );
};

export default ConfirmUser;
