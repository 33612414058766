import { Button, FrontBanner, Input } from 'components';
import { useFormik } from 'formik';
import * as yup from 'yup';

interface Props {
  model: IndustryValuesModel;
  onSubmit: (model: IndustryValuesModel) => void;
  next: () => void;
}

const validationSchema = yup.object().shape({
  linkedin: yup.string(),
  instagram: yup.string(),
});

export interface IndustryValuesModel {
  linkedin?: string;
  instagram?: string;
}

const IndustryStep: React.FC<Props> = ({ next, onSubmit, model }) => {
  const submitHandler = (values: IndustryValuesModel): void => {
    onSubmit(values);
    next();
  };

  const formik = useFormik({
    initialValues: {
      linkedin: model.linkedin,
      instagram: model.instagram,
    },
    validationSchema: validationSchema,
    onSubmit: submitHandler,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off" className='form-container'>
      <div>
        <FrontBanner mainText="Let’s get social" />
        <Input
          id={'instagram'}
          name={'instagram'}
          type={'text'}
          label={'Instagram'}
          prefix="@"
          value={formik.values.instagram || ''}
          onChange={formik.handleChange}
          isError={formik.touched.instagram && Boolean(formik.errors.instagram)}
          errorText={formik.errors.instagram}
        />
        <Input
          id={'linkedin'}
          name={'linkedin'}
          type={'text'}
          label={'Linkedin'}
          prefix="/"
          value={formik.values.linkedin || ''}
          onChange={formik.handleChange}
          isError={formik.touched.linkedin && Boolean(formik.errors.linkedin)}
          errorText={formik.errors.linkedin}
        />
        <Button type={'submit'} text={'Next'} />
      </div>
    </form>
  );
};

export default IndustryStep;
