import { SubscriptionsTypesResponse, CreateSubscriptionResponse } from 'types';
import axiosApi from './config';

const subscriptionApi = {
  getSubscriptions(): Promise<SubscriptionsTypesResponse> {
    const url = '/api/onboarding/subscription';
    return axiosApi.get(url);
  },

  createSubscription(priceId: string): Promise<CreateSubscriptionResponse> {
    const url = '/api/onboarding/subscription';
    return axiosApi.post(url, { priceId: priceId });
  },
};

export default subscriptionApi;
