import React from 'react';

interface Props {
  type: 'button' | 'submit';
  text: string;
  onClick?: () => void;
  containerStyle?: any;
  buttonStyle?: any;
  labelStyle?: any;
  disabled?: boolean;
}

const Button: React.FC<Props> = ({
  text,
  type,
  onClick,
  containerStyle,
  buttonStyle,
  labelStyle,
  disabled = false,
}) => {
  return (
    <div className="button-container" style={containerStyle}>
      <button disabled={disabled} className="button" type={type} style={buttonStyle} onClick={onClick}>
        <span className="button-text" style={labelStyle}>
          {text}
        </span>
      </button>
    </div>
  );
};

export default Button;
