import { useEffect, useState } from 'react';
import { copyIcon } from 'assets/icons';
import { Header } from 'components';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectProfile, selectReferrals } from 'redux/selectors/profile';
import { Status } from 'models/member';
import { MEMBER_ACCESS_TOKEN } from 'utils/storageKeys';
import { profileActions } from 'redux/reducers/profile';

const Referral = (): JSX.Element => {
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectProfile);
  const referrals = useAppSelector(selectReferrals);

  useEffect(() => {
    dispatch(profileActions.getReferrals());
    dispatch(profileActions.getProfile());
  }, [dispatch]);

  useEffect(() => {
    if (user && user.status !== Status.ACTIVE && user.status !== Status.PAST_DUE) {
      navigate('/');
    }
  }, [user, navigate]);

  const handleCopy = async (text: string) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      // for the older browsers
      document.execCommand('copy', true, text);
    }
  };

  const handleCopyClick = async (copyText: string) => {
    await handleCopy(copyText);
    setIsLinkCopied(true);
    setTimeout(() => {
      setIsLinkCopied(false);
    }, 1500);
  }

  const handleLogOut = () => {
    localStorage.removeItem(MEMBER_ACCESS_TOKEN);
    navigate('/login');
  };

  const inviteLink = `${window.origin}/login?referralCode=${user?.referralCode}`;

  const getPendingReferrals = (): number => {
    return (
      referrals?.filter(
        (x) =>
          x.status === Status.AWAITING_REVIEW || x.status === Status.APPROVED
      ).length || 0
    );
  };

  const getSuccessfullReferrals = (): number => {
    return (
      referrals?.filter(
        (x) => x.status === Status.ACTIVE || x.status === Status.PAST_DUE
      ).length || 0
    );
  };

  return (
    <>
      <Header
        onBack={() => navigate(-1)}
        withBackArrow
        hasLogo={false}
        title="Referral Dashboard"
      />
      <div className="page">
        <div className="container">
          <div className="referral-wrap">
            <span className="referral-title beaufortPro-normal">
              Your referral code
            </span>
            <div className="referral-code-wrap">
              <span className="referral-code">{user?.referralCode}</span>
            </div>
            <span className="referral-body beaufortPro-normal">
              Share this unique link with your people so they receive an
              expedited application review.
            </span>
            <div className="referral-link-wrap">
              <input
                type="text"
                value={inviteLink}
                className="referral-link dia-normal"
                readOnly
              />
              {isLinkCopied && (
                <div className="tooltip">
                  <span className="dia-normal copy-text">Copied!</span>
                </div>
              )}
              <img
                onClick={() => handleCopyClick(inviteLink)}
                src={copyIcon}
                alt=""
                className="referral-icon"
              />
            </div>
          </div>
          <div className="referral-wrap">
            <span className="referral-title beaufortPro-normal">
              Your referrals
            </span>
            <div className="your-referral-link-wrap upper-input">
              <input
                type="text"
                value={'Successful'}
                className="your-referral-link dia-bold"
                readOnly
              />
              <span className="dia-bold">{getSuccessfullReferrals()}</span>
            </div>
            <div className="your-referral-link-wrap">
              <input
                type="text"
                value={'Pending'}
                className="your-referral-link dia-bold"
                readOnly
              />
              <span className="dia-bold">{getPendingReferrals()}</span>
            </div>
          </div>
          <div className="logout-wrap">
            <span onClick={handleLogOut} className="logout dia-bold">
              log out
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Referral;
