import React, { useCallback, useEffect, useRef, useState } from 'react';
import { removeIcon } from 'assets/icons';
import useOnclickOutside from 'react-cool-onclickoutside';

export type OptionListsType = {
  key: string;
  value: string;
};

interface Props {
  id: string;
  name: string;
  type: 'single' | 'multiple';
  max?: number;
  options: OptionListsType[];
  value?: OptionListsType | OptionListsType[];
  searchValue: string;
  label: string;
  isError?: boolean;
  errorText?: string;
  onSelect: (value: OptionListsType) => void;
  onValueChange: (value: string) => void;
}

const AutoSelect: React.FC<Props> = ({
  id,
  name,
  options,
  value,
  searchValue,
  max,
  type,
  label,
  isError,
  errorText,
  onSelect,
  onValueChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const divRef = useOnclickOutside(() => {
    setOpenMenu(false);
  });

  const [openMenu, setOpenMenu] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isFocused, setFocused] = useState(false);

  const handleSelect = useCallback(
    (item: OptionListsType) => {
      if (max && Array.isArray(value) && max === value.length) {
        return;
      }
      onSelect(item);
      if (type === 'single') {
        setOpenMenu(false);
      }
    },
    [max, onSelect, type, value]
  );

  useEffect(() => {
    if (!openMenu) return;
    function handleKeyDown(event: KeyboardEvent) {
      const scrollList = listRef.current;
      const scrollStep = 48;
      if (event.key === 'ArrowUp') {
        setActiveIndex((prevIndex) => prevIndex - 1);
        if (scrollList && scrollPosition > scrollStep) {
          scrollList.scrollTo(0, scrollPosition - scrollStep);
          setScrollPosition(scrollPosition - scrollStep);
        }
      } else if (event.key === 'ArrowDown') {
        setActiveIndex((prevIndex) => prevIndex + 1);
        if (activeIndex >= 7 && scrollList) {
          scrollList.scrollTo(0, scrollPosition + scrollStep);
          setScrollPosition(scrollPosition + scrollStep);
        }
      } else if (event.key === 'Enter') {
        const selectedItem = options[activeIndex];
        handleSelect(selectedItem);
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeIndex, handleSelect, openMenu, options, scrollPosition]);

  return (
    <div>
      <div
        ref={divRef}
        tabIndex={1}
        className={`select-container ${isError ? 'error' : ''} ${isFocused ? 'focused' : ''}`}
        onClick={() => {
          inputRef.current?.focus();
          setFocused(true);
        }}
        onBlur={() => setFocused(false)}
      >
        <select style={{ display: 'none' }} />
        <span className={`input-label ${isError ? 'error' : ''}`}>{label}</span>
        <div>
          {type === 'multiple' && value && (
            <div className="select-values-container">
              {Array.isArray(value) &&
                value.map((item) => (
                  <div key={item.key} className="selected-value">
                    <span className="selected-value-text">{item.key}</span>
                    <div className="selected-value-icon" onClick={() => onSelect(item)}>
                      <img src={removeIcon} alt="Remove" />
                    </div>
                  </div>
                ))}
            </div>
          )}
          <div className="select-options-container">
            <input
              name={name}
              ref={inputRef}
              type={type}
              className="input"
              value={searchValue}
              max={max}
              onChange={(v) => {
                onValueChange(v.target.value);
                if (!v.target.value) {
                  setOpenMenu(false);
                } else {
                  setOpenMenu(true);
                }
              }}
            />
          </div>
          {openMenu && options.length > 0 && (
            <div ref={listRef} className="select-options-item-container">
              {options.map((item, index) => (
                <div
                  key={item.key}
                  className={`${
                    activeIndex === index ? 'active-options-item' : 'select-options-item'
                  }`}
                  onClick={() => handleSelect(item)}
                >
                  {item.value}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {isError && <span className="input-label-error">{errorText}</span>}
    </div>
  );
};

export default AutoSelect;
