import { useSearchParams } from 'react-router-dom';
import { EXPIDATE_CODE, PROMO_CODE, REFERRAL_CODE, TAG } from './storageKeys';

export default function useHandleSearchParams() {
  const [searchParams] = useSearchParams();

  const referralCode = searchParams.get('referralCode');
  if (referralCode) {
    sessionStorage.setItem(REFERRAL_CODE, referralCode);
  }

  const expediteCode = searchParams.get('expediteCode');
  if (expediteCode) {
    sessionStorage.setItem(EXPIDATE_CODE, expediteCode);
  }

  const promoCode = searchParams.get('promoCode');
  if (promoCode) {
    sessionStorage.setItem(PROMO_CODE, promoCode);
  }

  const tag = searchParams.get('tag');
  if (tag) {
    sessionStorage.setItem(TAG, tag);
  }
}
