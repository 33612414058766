import { AppConfig } from 'models/appConfig';
import { Country } from 'models/country';
import { City } from 'models/city';
import { publicAxiosApi } from './config';

const appApi = {
  getAppConfig(): Promise<AppConfig> {
    const url = 'api/v1/app-config';
    return publicAxiosApi.get(url);
  },

  getCountries(): Promise<Country[]> {
    const url = 'api/dictionary/countries';
    return publicAxiosApi.get(url);
  },

  getCities(): Promise<City[]> {
    const url = 'api/dictionary/cities';
    return publicAxiosApi.get(url);
  },
};

export default appApi;
