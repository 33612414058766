import { MemberDto, MemberProfile, HowYouKnowAboutUsType } from 'models/member';
import axiosApi from './config';

const profileApi = {
  getProfile(): Promise<MemberDto> {
    const url = 'api/onboarding/member/me';
    return axiosApi.get(url);
  },

  reApplyMember(data: { reApplyMessage: string }) {
    const url = 'api/onboarding/member/me/re-apply-member';
    return axiosApi.request({ url, method: 'POST', data });
  },

  updateProfile(data: MemberProfile): Promise<MemberDto> {
    const url = 'api/onboarding/member/me/profile';
    return axiosApi.put(url, data);
  },

  confrimMember(data: HowYouKnowAboutUsType) {
    const url = 'api/onboarding/member/me/confirm';
    return axiosApi.request({ url, method: 'PATCH', data });
  },

  getReferrals() {
    const url = 'api/onboarding/member/me/referrals';
    return axiosApi.get(url);
  },
};

export default profileApi;
