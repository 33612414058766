import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { MEMBER_ACCESS_TOKEN } from 'utils/storageKeys';

const PublicRoute = ({
  children,
  autoRedirect = true,
}: {
  children: JSX.Element;
  autoRedirect?: boolean;
}) => {
  const token = localStorage.getItem(MEMBER_ACCESS_TOKEN);

  let location = useLocation();
  if (!token || (token && !autoRedirect)) {
    return children;
  }

  return <Navigate to="/" state={{ from: location }} replace />;
};

export default PublicRoute;
