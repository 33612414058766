import { useEffect } from 'react';
import { quoteAuthorImage } from 'assets/images';
import { Header, DownloadAppButton } from 'components';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectProfile } from 'redux/selectors/profile';
import { Status } from 'models/member';
import { MEMBER_ACCESS_TOKEN } from 'utils/storageKeys';
import { profileActions } from 'redux/reducers/profile';

const Welcome = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(selectProfile);

  useEffect(() => {
    if (user?.status !== Status.ACTIVE && user?.status !== Status.PAST_DUE) {
      navigate('/');
    }
  }, [user, navigate]);

  useEffect(() => {
    dispatch(profileActions.getProfile());
  }, [dispatch]);

  const handleLogOut = () => {
    localStorage.removeItem(MEMBER_ACCESS_TOKEN);
    navigate('/login');
  };

  return (
    <>
      <Header withBackArrow={false}></Header>
      <div className="page">
        <div className="container">
          <div className="user-name">{`${user?.profile?.firstName || ''} ${
            user?.profile?.lastName || ''
          }`}</div>
          <div className="user-subscription-plan">{user?.subscription?.productName || ''}</div>
          <div className="quote-wrap">
            <div className="welcome-text-wrapper">
              <span className="beaufortPro-normal">
                Need anything? We got you. Just drop us a line at{' '}
                <a className="banner-description" href="mailto:support@mapout.com">
                  hello@yayem.co
                </a>
              </span>
            </div>
            <div className="author-wrap">
              <img className="author-image" src={quoteAuthorImage} alt="" />
              <div className="author-details">
                <span className="beaufortPro-normal name">Teri</span>
                <span className="position body-thin">VP of Marketing</span>
              </div>
            </div>
          </div>
          <DownloadAppButton
            className="download-app-btn"
            onClick={() => navigate('/download-app')}
          />
          <div className="referral-button" role="button" onClick={() => navigate('/refer')}>
            <span>Referral Dashboard</span>
            <p>Earn rewards by referring your people</p>
          </div>
          <div className="logout-wrap">
            <span onClick={handleLogOut} className="logout dia-normal">
              Log Out
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
