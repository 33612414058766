import React, { FC } from 'react';

type Props = {
  checked: boolean;
  onChange: () => void;
};

const Switch: FC<Props> = ({ checked, onChange }) => {
  return (
    <div className={`toggle-switch ${checked ? 'on' : 'off'}`} onClick={onChange}>
      <div className="slider"></div>
    </div>
  );
};

export default Switch;
