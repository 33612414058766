import React, { ChangeEvent, useRef } from 'react';

interface Props {
  id: string;
  name: string;
  maxLength?: number;
  value: string;
  label: string;
  isError?: boolean;
  errorText?: string;
  placeholder?: string;
  onChange: (value: ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea: React.FC<Props> = ({
  id,
  name,
  value,
  maxLength,
  label,
  isError,
  errorText,
  placeholder,
  onChange,
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  return (
    <div>
      <div
        className={`input-container ${isError ? 'error' : ''}`}
        onClick={() => inputRef.current?.focus()}
      >
        <span className={`input-label ${isError ? 'error' : ''}`}>{label}</span>
        <div
          style={{
            display: 'flex',
          }}
        >
          <textarea
            id={id}
            name={name}
            maxLength={maxLength}
            ref={inputRef}
            className="input"
            rows={8}
            placeholder={placeholder}
            value={value}
            onChange={(v) => onChange(v)}
          ></textarea>
        </div>
      </div>
      {isError && <span className="input-label-error">{errorText}</span>}
    </div>
  );
};

export default TextArea;
