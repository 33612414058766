import React, { FC } from 'react';
import { RadioItemType } from 'components/RadioItemsList';

type Props = {
  selected?: RadioItemType;
  options: RadioItemType[];
  onSelectItem: (item: RadioItemType) => void;
};

const SubscriptionsPlanList: FC<Props> = ({ selected, options, onSelectItem }): JSX.Element => {
  const handleSelectItem = (index: number) => {
    const currentOptionValue = options[index];
    onSelectItem(currentOptionValue);
  };

  return (
    <div className="subscription-plans">
      {options.length
        ? options.map((item, index) => (
            <div
              key={item.value}
              role="button"
              className={`subscription-plans__item ${
                selected?.value === item.value ? 'active-plan' : ''
              }`}
              onClick={() => handleSelectItem(index)}
            >
              <div className="subscription-plans__item-button">
                <div
                  className={`button-selector ${selected?.value === item.value ? 'active' : ''}`}
                />
              </div>
              <div className="subscription-plans-description">
                <div className="subscription-plans-description__item_label">{item.label}</div>
                <div className="subscription-plans-description__item_description">
                  {item.description}
                </div>
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

export default SubscriptionsPlanList;
