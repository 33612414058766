import { AxiosResponse } from 'axios';
import {
  LoginMemberRequest,
  LoginMemberResponse,
  SendMemberOtpRequest,
  SetPasswordRequest,
  TryLoginMemberRequest,
  TryLoginMemberResponse
} from 'types';
import axiosApi, { publicAxiosApi } from './config';

const authApi = {
  sendOtpCode(data: SendMemberOtpRequest): Promise<AxiosResponse> {
    const url = 'api/onboarding/auth/send-member-otp';
    return publicAxiosApi.post(url, data);
  },

  login(data: LoginMemberRequest): Promise<LoginMemberResponse> {
    const url = 'api/onboarding/auth/login-member';
    return publicAxiosApi.post(url, data);
  },

  tryLoginMember(data: TryLoginMemberRequest): Promise<TryLoginMemberResponse> {
    const url = 'api/onboarding/auth/try-login-member';
    return publicAxiosApi.post(url, data);
  },

  setPassword(data: SetPasswordRequest): Promise<AxiosResponse> {
    const url = 'api/onboarding/auth/password';
    return axiosApi.put(url, data);
  },
};

export default authApi;
