import { backArrowIcon, logoIcon, blackLogoIcon } from 'assets/icons';

interface Props {
  withBackArrow: boolean;
  onBack?: () => void;
  className?: string;
  hasLogo?: boolean;
  title?: string;
  isBlackLogo?: boolean;
}

const Header: React.FC<Props> = ({
  withBackArrow,
  onBack,
  className,
  hasLogo = true,
  title,
  isBlackLogo = false,
}) => {
  const headerClassName = className ?? '';
  return (
    <div className={`header ${withBackArrow ? 'header-back-btn' : ''} ${headerClassName}`}>
      {withBackArrow && (
        <span onClick={onBack} className="step-arrow-back">
          <img src={backArrowIcon} alt="Back" />
        </span>
      )}
      {hasLogo && <img src={isBlackLogo ? blackLogoIcon : logoIcon} alt="Logo"></img>}
      {title && <span className="title-bold">{title}</span>}
      {withBackArrow && <div></div>}
    </div>
  );
};

export default Header;
