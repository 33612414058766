import { Button, FrontBanner, TextArea } from 'components';
import { useFormik } from 'formik';
import * as yup from 'yup';

interface Props {
  model: TravelStoryValuesModel;
  onSubmit: (model: TravelStoryValuesModel) => void;
  next: () => void;
}

const validationSchema = yup.object().shape({
  travelStory: yup.string(),
});

export interface TravelStoryValuesModel {
  travelStory?: string;
}

const TravelStoryStep: React.FC<Props> = ({ next, onSubmit, model }) => {
  const submitHandler = (values: TravelStoryValuesModel): void => {
    next();
    onSubmit(values);
  };

  const formik = useFormik({
    initialValues: {
      travelStory: model.travelStory,
    },
    validationSchema: validationSchema,
    onSubmit: submitHandler,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off" className="form-container">
      <div>
        <FrontBanner mainText="Pack your bags..." subTitle="(we’re nearly done!)" />
        <TextArea
          id={'travelStory'}
          name={'travelStory'}
          label={'Tell us a crazy travel story'}
          value={formik.values.travelStory || ''}
          onChange={formik.handleChange}
          isError={formik.touched.travelStory && Boolean(formik.errors.travelStory)}
          errorText={formik.errors.travelStory}
        />
        <Button type={'submit'} text={'Next'} />
      </div>
    </form>
  );
};

export default TravelStoryStep;
