import { MemberDto, MemberProfile } from 'models/member';

const DEFAULT_ONBOARDING_STEP = 1;

type SchemaItem = {
    order: number;
    fields: Array<keyof MemberProfile>;
}

export const profileValidationSchema: SchemaItem[] = [
    {
        order: 1,
        fields: ['firstName', 'lastName', 'birthday'],
    },
    {
        order: 2,
        fields: ['countryOfResidence', 'secondaryCities'],
    },
    {
        order: 3,
        fields: ['linkedin', 'instagram'],
    },
    {
        order: 4,
        fields: ['travelStory'],
    },
];

export const getProfilesMissingStep = (data: MemberDto | null): number => {
    if (!data?.profile) return DEFAULT_ONBOARDING_STEP;
    const profile = data?.profile;
    let missingStep = DEFAULT_ONBOARDING_STEP;
    let shouldSkip = false;

    profileValidationSchema.forEach(el => {
        if (shouldSkip) return;
        el.fields.forEach(field => {
            if (!profile[field]) {
                missingStep = el.order;
                shouldSkip = true;
            } else if (el.order === profileValidationSchema.length) {
                missingStep = el.order;
            }
        })
    })

    return missingStep;
}
