import React, { FC } from 'react';
import { RadioItemType } from 'components/RadioItemsList';
import SubscriptionDetailInfo from './SubscriptionDetailInfo';
import { SubscriptionDetailsDto } from 'models/subscriptions';

type Props = {
  selected?: RadioItemType;
  options: RadioItemType[];
  onSelectItem: (item: RadioItemType) => void;
  handleOpenMobileDetails: (isOpen: boolean) => void;
  selectedSubscriptionInfo?: SubscriptionDetailsDto;
};

const MobileSubscriptionsDetails: FC<Props> = ({
  selected,
  options,
  onSelectItem,
  selectedSubscriptionInfo,
  handleOpenMobileDetails,
}): JSX.Element => {
  const handleSelectItem = (index: number) => {
    const currentOptionValue = options[index];
    onSelectItem(currentOptionValue);
  };

  return (
    <div className="subscription-plans mobile-details">
      {options.length
        ? options.map((item, index) =>
            selected?.value !== item.value ? (
              <div
                key={item.value}
                role="button"
                className="subscription-plans__item"
                onClick={() => handleSelectItem(index)}
              >
                <div className="subscription-plans-description">
                  <div className="subscription-plans-description__item_label">{item.label}</div>
                  <div className="subscription-plans-description__item_description">
                    {item.description}
                  </div>
                </div>
              </div>
            ) : (
              <SubscriptionDetailInfo selectedSubscriptionInfo={selectedSubscriptionInfo} />
            )
          )
        : null}
      <div
        role="button"
        className="close-details-button"
        title="Return to the pricing"
        onClick={() => handleOpenMobileDetails(false)}
      >
        X
      </div>
    </div>
  );
};

export default MobileSubscriptionsDetails;
