import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Button, FrontBanner } from 'components';
import AutoSelect from 'components/AutoSelect';
import { OptionListsType } from 'components/DropDown';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { appActions } from 'redux/reducers/app';
import { selectCities, selectCountries } from 'redux/selectors/app';
import * as yup from 'yup';

interface Props {
  model: CitiesValuesModel;
  onSubmit: (model: CitiesValuesModel) => void;
  next: () => void;
}

const validationSchema = yup.object().shape({
  countryOfResidence: yup.string().required('One of the dropdown options should be selected'),
  secondaryCities: yup
    .array<OptionListsType>()
    .min(1, 'Required')
    .required('Select at least one city from the dropdown'),
});

export interface CitiesValuesModel {
  countryOfResidence?: string;
  secondaryCities?: OptionListsType[];
}

const CitiesStep: React.FC<Props> = ({ next, onSubmit, model }) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchCityValue, setSearchCityValue] = useState('');
  const dispatch = useAppDispatch();
  const countries = useAppSelector(selectCountries);
  const selectedCountry = countries.find((c) => c.code === model.countryOfResidence);

  const cities = useAppSelector(selectCities);

  useEffect(() => {
    if (!selectedCountry) return;
    setSearchValue(selectedCountry.name);
  }, [selectedCountry]);

  useEffect(() => {
    dispatch(appActions.fetchCountries());
    dispatch(appActions.fetchCities());
  }, [dispatch]);

  const submitHandler = (values: CitiesValuesModel): void => {
    if (searchCityValue) {
      formik.setFieldError('secondaryCities', 'Please choose the city from the list');
      return;
    }

    next();
    onSubmit(values);
  };

  const formik = useFormik({
    initialValues: {
      countryOfResidence: model.countryOfResidence,
      secondaryCities: model.secondaryCities,
    },
    validationSchema: validationSchema,
    onSubmit: submitHandler,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off" className='form-container'>
      <div>
        <FrontBanner mainText="We know you’re global, but..." />
        <AutoSelect
          id="countryOfResidence"
          name="countryOfResidence"
          type="single"
          label="What country is your primary residence? (if you had to pick)"
          searchValue={searchValue || ''}
          options={countries
            .filter((c) => c.name.toLowerCase().includes(searchValue.toLowerCase()))
            .map((country) => ({
              key: country.code,
              value: country.name,
            }))}
          onSelect={(option) => {
            setSearchValue(option.value);
            formik.setFieldValue('countryOfResidence', option.key);
          }}
          onValueChange={(value) => {
            formik.setFieldValue('countryOfResidence', '');
            setSearchValue(value);
          }}
          isError={formik.touched.countryOfResidence && Boolean(formik.errors.countryOfResidence)}
          errorText={formik.errors.countryOfResidence}
        />

        <AutoSelect
          id="secondaryCities"
          name="secondaryCities"
          type="multiple"
          label="What cities do you call home?"
          value={formik.values.secondaryCities || []}
          searchValue={searchCityValue}
          options={cities
            .filter((c) => c.name.toLowerCase().includes(searchCityValue.toLowerCase()))
            .map((x) => ({
              key: x.name,
              value: x.name,
            }))}
          onValueChange={(value) => {
            setSearchCityValue(value);
          }}
          onSelect={(value) => {
            if (!formik.values.secondaryCities?.find((x) => x.key === value.key)) {
              formik.setFieldValue('secondaryCities', [
                ...(formik.values.secondaryCities || []),
                value,
              ]);
            } else {
              const array = formik.values.secondaryCities;
              array.splice(
                formik.values.secondaryCities.findIndex((x) => x.key === value.key),
                1
              );
              formik.setFieldValue('secondaryCities', [...array]);
            }
            setSearchCityValue('');
          }}
          isError={formik.touched.secondaryCities && Boolean(formik.errors.secondaryCities)}
          errorText={formik.errors.secondaryCities}
        />
        <Button type="submit" text="Next" />
      </div>
    </form>
  );
};

export default CitiesStep;
