import { useAppSelector } from 'app/hooks';
import { appStoreIcon, googlePlayIcon } from 'assets/icons';
import { Header } from 'components';
import { Status } from 'models/member';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectProfile } from 'redux/selectors/profile';

const DownloadApp = (): JSX.Element => {
  const navigate = useNavigate();

  const user = useAppSelector(selectProfile);

  useEffect(() => {
    if (user?.status !== Status.ACTIVE && user?.status !== Status.PAST_DUE) {
      navigate('/');
    }
  }, [user, navigate]);

  const onGoogleDownloadHandler = () => {
    window.location.href = process.env
      .REACT_APP_URL_DOWNLOAD_APP_ANDROID as string;
  };
  const onAppleDownloadHandler = () => {
    window.location.href = process.env.REACT_APP_URL_DOWNLOAD_APP_IOS as string;
  };

  return (
    <>
      <Header
        withBackArrow
        onBack={() => navigate(-1)}
        hasLogo={false}
        title="Download the App"
      />
      <div className="page download-page">
        <div className="container">
          <div onClick={onAppleDownloadHandler} className="download-btn">
            <img src={appStoreIcon} alt="app store icon" />
          </div>
          <div onClick={onGoogleDownloadHandler} className="download-btn">
            <img src={googlePlayIcon} alt="google store icon" />
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadApp;
