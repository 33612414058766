import React from 'react';
import { Button, Header } from 'components';
import { useNavigate } from 'react-router-dom';
import { quoteAuthorImage, successPaymentBanner } from 'assets/images';

const Success = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div>
      <Header withBackArrow={false}></Header>
      <div className="page">
        <div className="success-page">
          <div className="success-page__image-container">
            <img src={successPaymentBanner} alt="banner" />
          </div>
          <div className="success-page__info-container quote-wrap">
            <span className="beaufortPro-normal">Welcome to the fam!!</span>
            <br />
            <br />
            <span className="beaufortPro-normal">
              We're so happy to have you here. Keep a lookout for emails on how to get started and
              max out on your new membership. Killer experiences & global trips, access to beautiful
              spaces around the world (for work or play), a brand new community and the feeling that
              you are at home anywhere... magic awaits you
            </span>
            <div className="author-wrap">
              <img className="author-image" src={quoteAuthorImage} alt="author_avatar" />
              <div className="author-details">
                <span className="beaufortPro-normal name">Teri</span>
                <span className="position body-thin">VP of Marketing</span>
              </div>
            </div>
          </div>
        </div>
        <div  className="form-container success-button">
          <Button text="Continue" type="button" onClick={() => navigate('/welcome')} />
        </div>
      </div>
    </div>
  );
};

export default Success;
