import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { AppConfig } from 'models/appConfig';
import { City } from 'models/city';
import { Country } from 'models/country';

export interface AppState {
  loading: boolean;
  config: AppConfig | null;
  countries: Country[];
  cities: City[];
}

const initialState: AppState = {
  loading: false,
  config: null,
  countries: [],
  cities: [],
};

const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    fetchAppConfig(state) {
      state.loading = true;
    },
    fetchAppConfigSuccess(state, action: PayloadAction<AppConfig>) {
      state.loading = false;
      state.config = action.payload.data;
    },
    fetchAppConfigFailed(state, action: PayloadAction<any>) {
      state.loading = false;
    },
    fetchCountries(state) {
      state.loading = true;
    },
    fetchCountriesSuccess(state, action: PayloadAction<Country[]>) {
      state.loading = false;
      state.countries = action.payload;
    },
    fetchCountriesFailed(state, action: PayloadAction<any>) {
      state.loading = false;
    },
    fetchCities(state) {
      state.loading = true;
    },
    fetchCitiesSuccess(state, action: PayloadAction<City[]>) {
      state.loading = false;
      state.cities = action.payload;
    },
    fetchCitiesFailed(state, action: PayloadAction<any>) {
      state.loading = false;
    },
  },
});

// Actions
export const appActions = appSlice.actions;

// Selectors
export const selectAppLoading = (state: RootState) => state.app.loading;
export const selectAppConfig = (state: RootState) => state.app.config;

// Reducer
const appReducer = appSlice.reducer;
export default appReducer;
