import React, { useCallback, useEffect } from 'react';
import { Button, FrontBanner, Header, TextArea } from 'components';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { profileActions } from 'redux/reducers/profile';
import { MEMBER_ACCESS_TOKEN } from 'utils/storageKeys';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import * as yup from 'yup';
import { Status } from 'models/member';
import { selectProfile } from 'redux/selectors/profile';

const validationSchema = yup.object().shape({
  message: yup.string().required('Required'),
});

export interface InactiveMembersForm {
  message: string;
}

const Inactive = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(selectProfile);

  useEffect(() => {
    if (user?.status !== Status.INACTIVE) {
      navigate('/');
    }
  }, [user, navigate]);

  const submitHandler = useCallback(
    (values: InactiveMembersForm) => {
      dispatch(profileActions.reApplyMember({ reApplyMessage: values.message }));
    },
    [dispatch]
  );

  const handleLogOut = () => {
    localStorage.removeItem(MEMBER_ACCESS_TOKEN);
    navigate('/login');
  };

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: validationSchema,
    onSubmit: submitHandler,
    validateOnBlur: true,
  });

  return (
    <div>
      <Header withBackArrow={false}></Header>
      <form className="page" onSubmit={formik.handleSubmit}>
        <div className="login-container container">
          <FrontBanner
            mainText="Your membership is inactive"
            description="You can easily re-submit your original application – just drop us a note below."
          />
          <TextArea
            id="message"
            name="message"
            label="What’s new with you?"
            value={formik.values.message}
            onChange={formik.handleChange}
            isError={formik.touched.message && Boolean(formik.errors.message)}
            errorText={formik.errors.message}
          />
          <Button type="submit" text="RE-SUBMIT APPLICATION" />
          <div className="banner-text-container">
            <h5 className="banner-description">
              If you have any questions, please get in touch at <br />
              <a className="banner-description" href="mailto:support@mapout.com">
                hello@yayem.co
              </a>
            </h5>
          </div>
          <div className="banner-text-container">
            <div className="logout-wrap">
              <span onClick={handleLogOut} className="logout dia-bold">
                log out
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Inactive;
