export enum Reccuring {
  YEAR = 'year',
  MONTH = 'month',
}

export function getReccuringLabel(reccuring: string): string {
  switch (reccuring) {
    case Reccuring.MONTH: {
      return 'monthly';
    }
    case Reccuring.YEAR: {
      return 'annually';
    }
    default:
      return '';
  }
}
