import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ConfirmLogin from 'pages/ConfirmLogin';
import Layout from 'pages/Layout';
import Login from 'pages/Login';
import Onboarding from 'pages/onboarding/Onboarding';
import WaitingReview from 'pages/WaitingReview';
import useHandleSearchParams from 'utils/useHandleSearchParams';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import SetPassword from 'pages/SetPassword';
import SubscriptionPlan from 'pages/payment/SubscriptionPlan';
import Welcome from 'pages/Welcome';
import Referral from 'pages/Referral';
import DownloadApp from 'pages/DownloadApp';
import Inactive from 'pages/Inactive';
import Paused from 'pages/Paused';
import Success from 'pages/Success';
import GuideViewSignIn from 'pages/guideView/GuideViewSignIn';
import GuideView from 'pages/guideView/GuideView';
import ReactPixel from 'react-facebook-pixel';

const MainRouter = () => {
  useHandleSearchParams();

  const location = useLocation();

  useEffect(() => {
    ReactPixel.pageView();
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Layout />} />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/confirm-login"
        element={
          <PublicRoute>
            <ConfirmLogin />
          </PublicRoute>
        }
      />
      <Route
        path="/onboarding"
        element={
          <PrivateRoute>
            <Onboarding />
          </PrivateRoute>
        }
      />
      <Route
        path="/waiting-review"
        element={
          <PrivateRoute>
            <WaitingReview />
          </PrivateRoute>
        }
      />
      <Route
        path="/set-password"
        element={
          <PrivateRoute>
            <SetPassword />
          </PrivateRoute>
        }
      />
      <Route
        path="/membership-selection"
        element={
          <PrivateRoute>
            <SubscriptionPlan />
          </PrivateRoute>
        }
      />
      <Route
        path="/welcome"
        element={
          <PrivateRoute>
            <Welcome />
          </PrivateRoute>
        }
      />
      <Route
        path="/refer"
        element={
          <PrivateRoute>
            <Referral />
          </PrivateRoute>
        }
      />
      <Route
        path="/download-app"
        element={
          <PrivateRoute>
            <DownloadApp />
          </PrivateRoute>
        }
      />
      <Route
        path="/inactive"
        element={
          <PrivateRoute>
            <Inactive />
          </PrivateRoute>
        }
      />
      <Route
        path="/paused"
        element={
          <PrivateRoute>
            <Paused />
          </PrivateRoute>
        }
      />
      <Route
        path="/success"
        element={
          <PrivateRoute>
            <Success />
          </PrivateRoute>
        }
      />
      <Route
        path="/guide-view-request/:id"
        element={
          <PublicRoute autoRedirect={false}>
            <GuideViewSignIn />
          </PublicRoute>
        }
      />
      <Route
        path="/guide-view/:id"
        element={
          <PublicRoute autoRedirect={false}>
            <GuideView />
          </PublicRoute>
        }
      />
      <Route path="*" element={<Layout />} />
    </Routes>
  );
};

export default MainRouter;
