import appApi from 'api/app';
import { AppConfig } from 'models/appConfig';
import { City } from 'models/city';
import { Country } from 'models/country';
import { call, put, takeLatest } from 'redux-saga/effects';
import { appActions } from 'redux/reducers/app';

function* getAppConfig() {
  try {
    const response: AppConfig = yield call(appApi.getAppConfig);
    yield put(appActions.fetchAppConfigSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch app config`, error);
    yield put(appActions.fetchAppConfigFailed(error));
  }
}

function* getCountries() {
  try {
    const response: Country[] = yield call(appApi.getCountries);
    yield put(appActions.fetchCountriesSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch countries`, error);
    yield put(appActions.fetchCountriesFailed(error));
  }
}

function* getCities() {
  try {
    const response: City[] = yield call(appApi.getCities);
    yield put(appActions.fetchCitiesSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch cities`, error);
    yield put(appActions.fetchCitiesFailed(error));
  }
}

export default function* appSaga() {
  yield takeLatest(appActions.fetchAppConfig.type, getAppConfig);
  yield takeLatest(appActions.fetchCountries.type, getCountries);
  yield takeLatest(appActions.fetchCities.type, getCities);
}
